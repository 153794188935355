import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { Auth } from "../../api/auth";

export const useLoginCallback = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const state = params.get("state");
  const [, setCookie] = useCookies();

  useEffect(() => {
    (async () => {
      try {
        const redirectUrl = window.location.origin + "/login";

        if (!code || !state) {
          return;
        }
        const { data } = await Auth.verifyTwitch({ code, state, redirectUrl });
        const { token } = data;
        if (token) {
          setCookie("access_token", token, {
            path: "/",
            domain:
              process.env.NODE_ENV === "production" ? ".dono.gg" : undefined,
          });
        }
      } catch (e) {
        throw e;
      }
    })();
  }, [code, state, setCookie]);
};
