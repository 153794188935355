import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/Modal";

type ModalProps = {
  title: string;
  description: string;
  fullWidth?: boolean;
  onClose?: () => void;
  defaultState?: boolean;
};

export const useModal = ({
  title,
  description,
  fullWidth,
  onClose,
  defaultState = false,
}: ModalProps) => {
  let navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(defaultState);

  function closeModal() {
    if (onClose) {
      return onClose();
    }

    return setIsOpen(false);
  }

  // memoized component
  const ModalWithProps = useCallback(
    (props: any) => (
      <Modal
        {...{ title, description, fullWidth, isOpen, closeModal: closeModal }}
      >
        {props?.children}
      </Modal>
    ),
    [isOpen]
  );

  return {
    Modal: ModalWithProps,
    // closeModal,
    setIsOpen,
  };
};
