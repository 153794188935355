import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from "react";
import { useCookies } from "react-cookie";
import { Gateway } from "../api/gateway";
import { User } from "../api/user";
import type { GatewayType, UserType } from "../types";

const AuthContext = createContext<{
  user: undefined | null | UserType;
  gateway: undefined | null | GatewayType;
  donationLink?: string;
}>({
  user: undefined,
  gateway: undefined,
  donationLink: undefined,
});

export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [gateway, setGateway] = useState<GatewayType | undefined>();
  const [user, setUser] = useState<undefined | null | UserType>();
  const [cookies] = useCookies();
  const currentPath = window.location.pathname;
  const donationLink =
    gateway && `${process.env.REACT_APP_DONATION_APP}/${gateway?.slug}`;

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          return;
        }
        if (currentPath === "/login/set-gateway") {
          return;
        }
        const data = await Gateway.getSelf();
        if (!data) {
          throw new Error("no-gateway");
        }
        setGateway(data?.data);
      } catch (e: any) {
        const newLogin = e?.message === "no-gateway";

        if (newLogin) {
          if (currentPath !== "/gateway") {
            window.location.href = "/login/set-gateway";
          }
        }
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        if (!cookies?.access_token) {
          return;
        }
        const { data } = await User.getSelf();
        if (!data) {
          return;
        }
        setUser(data);
      } catch (e) {
        console.error(e);
        setUser(null);
      }
    })();
  }, [cookies?.access_token]);

  return {
    user,
    gateway,
    setGateway,
    donationLink,
  };
}
