import * as ReactDOMClient from "react-dom/client";
import "./index.scss";
import { App } from "./App";
import { ProvideAuth } from "./hooks/useProvideAuth";

const container = document.getElementById("root") as HTMLElement;

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <ProvideAuth>
    <App />
  </ProvideAuth>
);
