import React from "react";
import { Sidebar } from "../pages/dashboard/components/Sidebar";
import { Header } from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="pb-10">
      <Sidebar />
      <div className="mr-[250px]">
        <Header />

        {children}
      </div>
    </div>
  );
};
