import { ReactNode } from "react";
import { classNames } from "../utils/classNames";

export const Container = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: any;
}) => {
  return (
    <div
      className={classNames(
        "container mx-auto",
        className || "px-10 2xl:px-14"
      )}
    >
      {children}
    </div>
  );
};
