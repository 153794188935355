import { model } from "../model";
import { Alert } from "../../../api/alert";
import { Card } from "../../../components/Card";
import Table from "../../../components/Table";
import { useFetch } from "../../../hooks/useFetch";
import { useEffect } from "react";

export const Payments = () => {
  const {
    data: payments,
    loading,
    fetchData,
  } = useFetch((p) => Alert.Donation.getAll(p));

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData((p: any) => Alert.Donation.getAll(p));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card title="پرداخت ها">
      <Table
        data={payments?.data}
        columns={model}
        loading={loading}
        pageCount={payments?.pagination?.last}
        fetchData={fetchData}
      />
    </Card>
  );
};
