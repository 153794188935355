export const PaypingIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 12V22H4.1267H6.25339V18.982V15.964L9.62443 15.9624C13.2397 15.9607 13.6709 15.9205 14.757 15.4838C17.699 14.3008 19.5319 11.216 19.1206 8.14009C18.8932 6.44 18.2725 5.20604 17.0216 3.96721C16.3417 3.29388 16.1043 3.12469 15.2932 2.73559C13.741 1.991 13.8359 2.00001 7.52516 2.00001H2V12ZM13.0708 6.20991C14.994 7.09352 15.5092 9.4409 14.1137 10.961C13.8456 11.253 13.4549 11.5534 13.1484 11.7032L12.6335 11.955L9.44344 11.9827L6.25339 12.0105V8.98298V5.95541L9.44344 5.98217C12.5225 6.00811 12.6487 6.01604 13.0708 6.20991ZM16.3891 19.1622V22H19.1946H22V19.1622V16.3243H19.1946H16.3891V19.1622Z"
      />
    </svg>
  );
};
