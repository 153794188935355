import { get, post, patch, remove } from "../config/user-api";

export const Overlay = {
  getAll: () => get(`overlay`),
  update: ({ id, payload }: { id: string; payload: any[] }) =>
    patch(`overlay/${id}`, payload),
  create: ({ name }: { name: string }) => post(`overlay`, { name }),
  getById: (id: string) => get(`overlay/${id}`),
  remove: (id: string) => remove(`overlay/${id}`),
};
