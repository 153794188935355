import axios from "axios";
import { AxiosError } from "axios";
import { type } from "os";
import Cookies from "universal-cookie";
import toast from "../helpers/toast";

const cookies = new Cookies();

export const errorHandler = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const forbidden = error.response?.status === 403;
    const unauthorized = error.response?.status === 401;

    if (forbidden || unauthorized) {
      cookies.remove("access_token");
      // if not already on login page
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }

      return;
    }

    if (typeof error?.response?.data !== "undefined") {
      const { data }: any = error?.response;
      if (typeof data === "undefined") {
        return;
      }
      if (typeof data?.message !== "undefined") {
        toast.error(data?.message);
      }
    }
  } else {
    // Just a stock error
  }

  //errors?.forEach((error: string) => toast.error(error));

  return Promise.reject({ ...error });
};
