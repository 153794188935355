export const VandarIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 9.2896V16.5791H12H22L21.9745 10.2473C21.9494 4.0371 21.9454 3.90789 21.763 3.51563C21.4859 2.9196 21.1544 2.57559 20.5897 2.29824L20.0829 2.04932L11.0414 2.02471L2 2V9.2896ZM18.3945 9.292V12.98H11.9967H5.5988L5.57552 9.38095C5.56281 7.40152 5.57161 5.73171 5.59525 5.67021C5.62919 5.58152 6.95746 5.56313 12.0163 5.58117L18.3945 5.60401V9.292ZM2 20.2227V22H11.9967H21.9933V20.2227V18.4453H11.9967H2V20.2227Z"
      />
    </svg>
  );
};
