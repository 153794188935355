import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import notFound from "../../images/coyotes/5.png";

export const ComingSoonPage = () => {
  return (
    <Layout>
      <Container>
        <div className="flex flex-col items-center justify-center h-[calc(100vh_-_160px)]">
          <img src={notFound} className="w-48 h-48 rounded-3xl mb-8" />
          <h1 className="text-3xl font-bold text-center mb-4">
            اینجای سفینه خرابه!
          </h1>
          <p className="text-center text-sm text-gray-500">
            این صفحه در حال توسعه است و در زمانی که آماده شد، در اینجا قرار
            خواهد گرفت.
          </p>
        </div>
      </Container>
    </Layout>
  );
};
