import { MiniFooter } from "../../../components/MiniFooter";
import { Container } from "../../../components/Container";
import { Logo } from "../../../components/Logo";
import { LoginHero } from "./LoginHero";

export const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex">
      <Container className="px-[100px] relative">
        <div className="h-screen flex flex-col justify-between py-6 2xl:py-10 px-6 gap-y-14">
          <Logo />
          {children}
          <MiniFooter />
        </div>
        <LoginHero />
      </Container>
    </div>
  );
};
