import { ReactNode, useEffect, useState } from "react";
import {
  ActivityProps,
  CheerActivity,
  DonationActivity,
  SubActivity,
} from "./Activities";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { RefreshIcon } from "@heroicons/react/outline";

timeago.register("fa", fa);

const currencyDefinition = {
  IRT: "تومان",
  USD: "دلار آمریکا",
};

const CardHeading = ({
  title,
  subtitle,
}: {
  title: string | ReactNode;
  subtitle: string | ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-y-1">
      <h3 className="text-sm font-medium mb-0.5">{title}</h3>
      <h3 className="text-xs opacity-90">{subtitle}</h3>
    </div>
  );
};

const SubCard = (props: SubActivity) => {
  return (
    <div className="flex gap-x-3.5">
      <div
        className="w-[5px] h-[30px] rounded-[18px] bg-[#BC97FF]"
        style={{ boxShadow: "2px 2px 10px rgba(188, 151, 255, 0.5)" }}
      />
      <CardHeading
        title={
          <>
            <strong>{props?.buyerName}</strong> شما را به صورت Tier{" "}
            {props?.tier} سابسکرایب کرد.
          </>
        }
        subtitle={
          <TimeAgo
            datetime={props?.updatedAt}
            locale="fa"
            className="dono-dock-time px-1 mb-0"
          />
        }
      />
    </div>
  );
};

const CheerCard = (props: CheerActivity) => {
  return (
    <div className="flex gap-x-3.5">
      <div
        className="w-[5px] h-[30px] rounded-[18px] bg-[#ff97fc]"
        style={{ boxShadow: "2px 2px 10px #ea97ff80" }}
      />
      <CardHeading
        title={
          <>
            <strong>{props?.buyerName || "ناشناس"}</strong> مقدار {props?.bits}{" "}
            بیت ارسال کرد.
          </>
        }
        subtitle={
          <div className="flex flex-col gap-y-4">
            {props?.message && <em>{props?.message}</em>}
            <TimeAgo
              datetime={props?.updatedAt}
              locale="fa"
              className="dono-dock-time px-1 mb-0"
            />
          </div>
        }
      />
    </div>
  );
};

const DonationCard = (props: DonationActivity) => {
  return (
    <div className="flex gap-x-3.5">
      <div
        className="w-[5px] h-[30px] rounded-[18px] bg-indigo-500"
        style={{ boxShadow: "2px 2px 10px #97ffca80" }}
      />
      <CardHeading
        title={
          <>
            <strong>{props?.donator || "ناشناس"}</strong> مبلغ{" "}
            {props?.amount?.toLocaleString()} {currencyDefinition["IRT"]} حمایت
            کرد.
          </>
        }
        subtitle={
          <div className="flex flex-col gap-y-4">
            {props?.message && <em>{props?.message || "بدون پیام"}</em>}
            <TimeAgo
              datetime={props?.updatedAt}
              locale="fa"
              className="dono-dock-time px-1 mb-0"
            />
          </div>
        }
      />
    </div>
  );
};

export const ActivityCard = (props: ActivityProps) => {
  return (
    <div className="bg-[#F7F9FF] px-10 py-5 min-h-[90px] flex-shrink-0 justify-between rounded-2xl flex">
      {/* {props?.type === "DONO_SUB" && <SubCard {...props} />}
      {props?.type === "DONO_CHEER" && <CheerCard {...props} />} */}
      <DonationCard {...(props as DonationActivity)} />
      <RefreshIcon className="w-4 h-4 text-[#A3A5B2] -ml-2" />
    </div>
  );
};
