import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import { TutorialCard } from "./components/TutorialCard";
import { Card as OverlayCard } from "./components/Card";
import overlayDefault from "../../images/overlays/1.png";
import { Overlay } from "../../api/overlay";
import { NewOverlayCard } from "./components/NewOverlayCard";
import { Route, Routes } from "react-router-dom";
import { OverlayForm } from "./components/OverlayForm";
import { useEffect, useState } from "react";

type IOverlay = {
  id: string;
  userId: string;
  key: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export const Overlays = () => {
  const [overlays, setOverlays] = useState<IOverlay[]>([]);

  const getOverlay = async () => {
    const { data } = await Overlay.getAll();
    if (!data) return;
    setOverlays(data);
  };

  useEffect(() => {
    (async () => {
      getOverlay();
    })();
  }, []);

  return (
    <Layout>
      <Container>
        <TutorialCard />
        <h2 className="font-semibold mb-6">نمایه ها</h2>
        <div className="grid grid-cols-4 gap-x-12">
          {overlays?.map((overlay: IOverlay) => (
            <OverlayCard
              key={overlay?.id}
              id={overlay?.id}
              title={overlay?.name}
              image={overlayDefault}
              date={overlay?.updatedAt}
            />
          ))}
          <NewOverlayCard />
        </div>
        <Routes>
          <Route
            path="new"
            element={<OverlayForm refresh={getOverlay} add />}
          />
          <Route
            path=":overlayId"
            element={<OverlayForm refresh={getOverlay} />}
          />
        </Routes>
      </Container>
    </Layout>
  );
};
