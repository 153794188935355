import { useEffect, useRef, useState } from "react";
import { Alert } from "../../../api/alert";
import { Card } from "../../../components/Card";
import { ActivityCard } from "./ActivityCard";

export interface CheerActivity extends ActivityBaseProps {
  type: "DONO_CHEER";
  bits: 1;
  message: string;
  processedAt: Date;
  streamerTwitchId: string;
  buyerName?: string;
}

export interface SubActivity extends ActivityBaseProps {
  type: "DONO_SUB";
  buyerName: string;
  months: number;
  processedAt: Date;
  quantity: number;
  streamerTwitchId: string;
  targetTwitchId: string;
  targetTwitchName: string;
  tier: 1 | 2 | 3;
}

export interface DonationActivity extends ActivityBaseProps {
  type: "donation";
  amount: number;
  donator: string;
  message: string;
  currency: "IRT" | "USD";
  exponent: number;
  paymentId: string;
  paymentMethodType: string;
}

export type ActivityBaseProps = {
  id: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type ActivityProps = SubActivity | DonationActivity | CheerActivity;

export const Activities = () => {
  const [activities, setActivities] = useState<ActivityProps[]>();
  const [page, setPage] = useState(1);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const ref = useRef<any>();

  useEffect(() => {
    ref.current.onscroll = (e: any) => {
      if (ref.current.scrollTop < lastScrollTop) {
        // upscroll
        return;
      }
      const lastPosition =
        ref.current.scrollTop <= 0 ? 0 : ref.current.scrollTop;
      setLastScrollTop(lastPosition);
      if (
        ref.current.scrollTop + ref.current.offsetHeight >=
        ref.current.scrollHeight
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await Alert.Donation.getAll({ page });
        if (!data) {
          return;
        }
        setActivities((prevActivities) =>
          prevActivities ? [...prevActivities, ...data] : data
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [page]);

  return (
    <Card title="آخرین فعالیت ها">
      <div
        ref={ref}
        className="flex flex-col gap-y-4 h-[300px] overflow-auto hide-scroll"
      >
        {activities?.map((activity: ActivityProps) => (
          <ActivityCard {...activity} />
        ))}
      </div>
    </Card>
  );
};
