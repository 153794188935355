import { MouseEventHandler, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Overlay } from "../../../api/overlay";
import Input from "../../../components/Input";
import toast from "../../../helpers/toast";
import { useModal } from "../../../hooks/useModal";

export const OverlayForm = ({
  refresh,
  add,
  edit,
}: {
  refresh: any;
  add?: boolean;
  edit?: boolean;
}) => {
  const { processorId: id } = useParams();
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    navigate("/overlays");
  };

  const { Modal, setIsOpen } = useModal({
    title: add ? "افزودن نمایه" : `درگاه ${id}`,
    description: "از طریق این فرم میتوانید یک نمایه جدید اضافه کنید.",
    defaultState: true,
    onClose: closeModal,
  });

  return (
    <>
      <Modal>
        <Form {...{ closeModal, refresh, add, id }} />
      </Modal>
    </>
  );
};

const Form = ({
  closeModal,
  refresh,
  id,
  add,
}: {
  closeModal: () => void;
  refresh: () => void;
  id?: string;
  add?: boolean;
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;
  const selectedType = watch("type");

  const handleRemove = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!id) return;
    const { data } = await Overlay.remove(id);
    if (!data) {
      return;
    }
    toast.success("پردازشگر با موفقیت حذف شد.");
    refresh();
    closeModal();
  };

  useEffect(() => {
    (async () => {
      try {
        if (!id || id === "add") {
          return;
        }

        const { data } = await Overlay.getById(id);
        if (!data) {
          return;
        }

        reset(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id]);

  const onSubmit = async (data: any) => {
    try {
      if (!add && !id) {
        return;
      }
      const { data: response } = add
        ? await Overlay.create(data)
        : await Overlay.update({ id: id as string, payload: data });
      if (!response) {
        return;
      }
      toast.success("با موفقیت ذخیره شد.");
      refresh();
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <Input id="name" label="نام" placeholder="نمایه جدید" />

        <div className="flex space-x-2 space-x-reverse">
          <div>
            <button className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500">
              {add ? "افزودن نمایه" : "ذخیره"}
            </button>
          </div>

          {!add && (
            <button
              onClick={handleRemove}
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            >
              حذف
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
