import { ZarinpalIcon } from "../images/payment-accounts/zarinpal";
import { PaypingIcon } from "../images/payment-accounts/payping";
import { VandarIcon } from "../images/payment-accounts/vandar";
import { IdpayIcon } from "../images/payment-accounts/idpay";

export const paymentAccounts: {
  [key: string]: {
    name: string;
    logo: (props: any) => JSX.Element;
  };
} = {
  zarinpal: {
    name: "زرین پال",
    logo: ZarinpalIcon,
  },
  payping: {
    name: "پی پینگ",
    logo: PaypingIcon,
  },
  vandar: {
    name: "وندار",
    logo: VandarIcon,
  },
  idpay: {
    name: "ای دی پی",
    logo: IdpayIcon,
  },
};
