import { FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import Input from "../../components/Input";
import { Button } from "../../components/Button";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import toast from "../../helpers/toast";
import { Goal } from "../../api/goal";
import axios from "axios";
import { TutorialCard } from "./components/TutorialCard";

export const DonationGoal = () => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;
  const [currentGoal, setCurrentGoal] = useState<any>(null);
  const [firstTime, setFirstTime] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    try {
      if (firstTime) {
        const { data } = await Goal.create(values);
        if (!data) return;
        toast.success("با موفقیت ذخیره شد");
        setFirstTime(false);
        return;
      }
      const { data } = await Goal.update(values);
      if (!data) return;
      toast.success("با موفقیت ذخیره شد");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await Goal.getSelf();
        if (!data) return;
        setCurrentGoal(data);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          const status = e?.response?.status;
          if (status === 404) setFirstTime(true);
        }
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!currentGoal) return;
    reset(currentGoal);
  }, [currentGoal]);

  const maxAmount = watch("maxAmount");

  return (
    <Layout>
      <Container>
        {firstTime && <TutorialCard />}

        <h2 className="font-semibold mb-6">هدف حمایت</h2>

        <FormProvider {...methods}>
          <div className="max-w-lg">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <Input id="title" label="عنوان هدف" />

              <Input id="progressAmount" label="میزان پر شده" />

              <Input id="maxAmount" label="میزان هدف" />
              {maxAmount && !isNaN(+maxAmount) && (
                <div className="flex justify-between">
                  <span className="text-gray-500">میزان هدف</span>
                  <span className="text-gray-500">
                    {!isNaN(+maxAmount) && +maxAmount?.toLocaleString()} تومان
                  </span>
                </div>
              )}

              <Button type="primary" block className="mt-4">
                ذخیره
              </Button>
            </form>
          </div>
        </FormProvider>
      </Container>
    </Layout>
  );
};
