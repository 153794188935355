import React from "react";
import { useTable, usePagination } from "react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { classNames } from "../utils/classNames";
import { SortIcon, SortUpIcon, SortDownIcon } from "../misc/sortIcons";
import { Button } from "./Button";
import { Spinner } from "./Spinner";

export function PageButton({ children, className, ...rest }: any) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2 border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

function TableComponent({
  columns,
  data,
  loading,
  pageCount: controlledPageCount,
  fetchData,
}: any) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // Get the state from the instance
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  React.useEffect(() => {
    if (!fetchData) {
      return;
    }
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <div className="relative">
      {loading && (
        <div className="bg-white opacity-40 absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {data?.length > 0 && (
        <>
          {/* table */}
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow-gray-100 border-gray-200 sm:rounded-lg">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-gray-200"
                  >
                    <thead className="bg-[#F8F9FF]">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, id) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              scope="col"
                              className="group px-6 py-3.5 text-left text-sm font-bold text-black"
                              // @ts-ignore
                              key={column?.Header}
                            >
                              <div className="flex items-center justify-between">
                                {column.render("Header")}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {page.map((row: any, i: any) => {
                        // new
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="px-6 py-4.5 h-20 text-15px"
                                  style={{ fontSize: "14px" }}
                                  role="cell"
                                >
                                  {cell.column.Cell.name ===
                                  "defaultRenderer" ? (
                                    <div className="text-sm text-gray-700">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Pagination */}
          <div className="flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
              <Button
                type="secondary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </Button>
              <Button
                type="secondary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </Button>
            </div>
            <div className="mt-2 hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="flex gap-x-2 items-center">
                <PageButton
                  onClick={fetchData}
                  type="secondary"
                  className="rounded-md"
                >
                  <RefreshIcon className="w-3 h-3" />
                </PageButton>
                <span className="text-sm text-gray-700">
                  صفحه <span className="font-medium">{pageIndex + 1}</span> از{" "}
                  <span className="font-medium">{pageOptions.length}</span>
                </span>
                <label>
                  <span className="sr-only">Items Per Page</span>
                  <select
                    className="mt-1 block w-full border text-sm py-1 px-1 rounded-md border-gray-200 shadow-sm focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        نمایش {pageSize}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <div className="-ml-px">
                    <PageButton
                      className="rounded-r-md"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">First</span>
                      <ChevronDoubleRightIcon
                        className="h-4 w-4 text-gray-400"
                        aria-hidden="true"
                      />
                    </PageButton>
                  </div>
                  <PageButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronRightIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </PageButton>
                  <PageButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronLeftIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </PageButton>
                  <PageButton
                    className="rounded-l-md"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleLeftIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </PageButton>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const Table = (props: any) => {
  if (props?.forceLoad) {
    return <TableComponent {...props} />;
  }

  return props?.data?.length ? (
    <TableComponent {...props} />
  ) : props?.loading ? (
    <div className="mt-8">
      <Spinner />
    </div>
  ) : (
    <div className="flex flex-col items-center gap-y-1.5">
      <h2 className="font-bold text-xl">متاسفیم!</h2>
      <span className="text-gray-600">چیزی برای نمایش وجود ندارد.</span>
    </div>
  );
};

export default Table;
