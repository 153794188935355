import React, { useEffect } from "react";
import { paymentAccounts } from "../../../config/paymentAccounts";
import { useModal } from "../../../hooks/useModal";
import { AccountType } from "../../../types";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { Button } from "../../../components/Button";
import { PaymentAccount } from "../../../api/payment-account";
import toast from "../../../helpers/toast";

export const AccountsModal = (props: {
  account: AccountType;
  setSelectedAccount: (account: AccountType | undefined) => void;
  getAccounts: () => void;
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;

  const { Modal, setIsOpen } = useModal({
    title: paymentAccounts[props?.account?.type]?.name,
    description: "اطلاعات درگاه خود را با دقت وارد کنید.",
    onClose: () => {
      props.setSelectedAccount(undefined);
    },
  });

  useEffect(() => {
    if (!props?.account) {
      return;
    }
    setIsOpen(true);

    (async () => {
      if (!props?.account?.id) {
        return;
      }
      const { data } = await PaymentAccount.getById(props?.account?.id);
      if (!data) {
        return;
      }
      reset(data);
    })();
  }, [props?.account]);

  const onSubmit = async (payload: any) => {
    try {
      const existingAccount = props?.account?.created;
      if (existingAccount) {
        if (!props?.account?.id) {
          return;
        }
        const { data } = await PaymentAccount.update(props?.account?.id, {
          ...payload,
          type: props?.account?.type,
        });
        if (!data) {
          return;
        }
        toast.success("درگاه پرداختی با موفقیت ویرایش شد.");
        props.getAccounts();
        return;
      }

      const newPayload = {
        ...payload,
        type: props?.account?.type,
      };
      const { data } = await PaymentAccount.create(newPayload);
      if (!data) {
        return;
      }
      toast.success("درگاه پرداختی با موفقیت ایجاد شد.");

      props.getAccounts();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <Input
            type="text"
            id="metadata.merchantCode"
            placeholder="کد پذیرنده"
            validation={{ required: "لطفا کد پذیرنده را وارد کنید" }}
          />
          <Button
            type="primary"
            block
            disabled={!watch("metadata.merchantCode")}
          >
            ذخیره
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};
