import { FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { Button } from "../../components/Button";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useAuth } from "../../hooks/useProvideAuth";
import { useEffect } from "react";
import { Gateway } from "../../api/gateway";
import toast from "../../helpers/toast";

export const DonationPage = () => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;
  const { gateway, donationLink } = useAuth();

  const onSubmit = async (values: any) => {
    try {
      const { data } = await Gateway.update(values);
      if (!data) return;
      toast.success("با موفقیت ذخیره شد");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!gateway) return;
    const newGateway = {
      ...gateway,
      minAmount: gateway?.minAmount === "0" ? "" : gateway?.minAmount,
      maxAmount: gateway?.maxAmount === "0" ? "" : gateway?.maxAmount,
    };
    reset(newGateway);
  }, [gateway]);

  return (
    <Layout>
      <Container>
        <h2 className="font-semibold mb-6">صفحه حمایت من</h2>

        <FormProvider {...methods}>
          <div className="max-w-lg">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <Input id="name" label="عنوان" />

              <Input id="description" label="توضیحات" />

              <div className="flex gap-8">
                <Input id="minAmount" label="حداقل دونیت" />

                <Input id="maxAmount" label="حداکثر دونیت" />
              </div>

              <div
                className="rounded-[10px] overflow-hidden border border-gray-300 mb-6"
                style={{
                  direction: "ltr",
                }}
              >
                <div className="flex -my-1">
                  <div className="bg-gray-100 flex items-center my-1 px-3 text-gray-600">
                    https://dono.gg/
                  </div>
                  <Input
                    id="slug"
                    placeholder="نام درگاه"
                    style={{
                      borderRadius: 0,
                      border: 0,
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between gap-8">
                <div className="w-48">
                  <Button type="primary" block className="mt-4">
                    ذخیره
                  </Button>
                </div>
                <a
                  target="_blank"
                  href={donationLink}
                  className="flex gap-1 items-center text-indigo-600"
                >
                  <span className="text-base">مشاهدهٔ صفحه </span>
                  <ExternalLinkIcon className="h-4 w-4" />
                </a>
              </div>
            </form>
          </div>
        </FormProvider>
      </Container>
    </Layout>
  );
};
