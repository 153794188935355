export const LogoIcon = () => {
  return (
    <svg
      width="244"
      height="44"
      viewBox="0 0 244 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M169.396 24.692C170.641 24.4422 171.513 24.2445 172.013 24.0988C172.513 23.9635 172.864 23.8022 173.066 23.6149C173.279 23.438 173.38 23.183 173.369 22.85H171.662C170.726 22.85 169.976 22.5638 169.412 21.9914C168.859 21.4086 168.582 20.6281 168.582 19.6499C168.582 19.0359 168.715 18.4635 168.981 17.9328C169.258 17.3916 169.635 16.9598 170.114 16.6372C170.603 16.3145 171.146 16.1532 171.742 16.1532C172.327 16.1532 172.859 16.3093 173.337 16.6215C173.827 16.9233 174.22 17.35 174.518 17.9016C174.827 18.4427 175.018 19.0619 175.093 19.7592L175.188 20.6333H176.098L176.226 21.7417L176.098 22.85H176.05H175.396C175.406 23.6721 175.226 24.3225 174.853 24.8012C174.491 25.2799 173.922 25.665 173.146 25.9564C172.369 26.2582 171.252 26.5548 169.795 26.8462L169.396 24.692ZM170.609 19.6499C170.609 19.9933 170.683 20.2431 170.832 20.3992C170.992 20.5553 171.268 20.6333 171.662 20.6333H173.146L173.082 19.9933C173.018 19.4626 172.869 19.0619 172.635 18.7913C172.412 18.5104 172.125 18.3699 171.773 18.3699C171.412 18.3699 171.125 18.4948 170.912 18.7445C170.71 18.9839 170.609 19.2857 170.609 19.6499Z"
        fill="#1F2937"
      />
      <path
        d="M175.946 20.6333H177.206C177.664 20.6333 177.999 20.5709 178.212 20.446C178.435 20.3211 178.547 20.1026 178.547 19.7904C178.547 19.6343 178.515 19.4418 178.451 19.2128L177.813 16.8869L179.759 16.325L180.398 18.6509C180.504 19.0567 180.557 19.4522 180.557 19.8372C180.557 20.753 180.249 21.4867 179.632 22.0382C179.015 22.5794 178.206 22.85 177.206 22.85H175.946V20.6333ZM177.015 13H179.105V15.0449H177.015V13Z"
        fill="#1F2937"
      />
      <path
        d="M183.062 24.692C184.318 24.4422 185.201 24.2445 185.711 24.0988C186.222 23.9531 186.573 23.7814 186.764 23.5836C186.956 23.3859 187.046 23.0997 187.036 22.7251C186.546 22.8083 185.977 22.85 185.328 22.85C184.732 22.85 184.201 22.7147 183.732 22.4441C183.264 22.1735 182.897 21.7937 182.631 21.3046C182.376 20.8155 182.248 20.2639 182.248 19.6499C182.248 19.0359 182.381 18.4635 182.647 17.9328C182.924 17.3916 183.302 16.9598 183.78 16.6372C184.27 16.3145 184.812 16.1532 185.408 16.1532C186.312 16.1532 187.068 16.4863 187.674 17.1523C188.28 17.8079 188.642 18.6717 188.759 19.7436L189.03 22.2724C189.052 22.4389 189.062 22.6679 189.062 22.9592C189.062 23.7502 188.876 24.3798 188.504 24.8481C188.131 25.3268 187.557 25.7066 186.78 25.9876C186.004 26.279 184.897 26.5652 183.461 26.8462L183.062 24.692ZM184.275 19.6655C184.275 20.3107 184.626 20.6333 185.328 20.6333C185.871 20.6333 186.365 20.5969 186.812 20.5241L186.764 20.0245C186.711 19.4938 186.568 19.0879 186.333 18.807C186.099 18.5156 185.802 18.3699 185.44 18.3699C185.078 18.3699 184.791 18.4948 184.578 18.7445C184.376 18.9943 184.275 19.3013 184.275 19.6655Z"
        fill="#1F2937"
      />
      <path
        d="M193.629 22.85C193.076 22.85 192.587 22.7563 192.161 22.569C191.736 22.3713 191.4 22.1059 191.156 21.7729C190.922 21.4399 190.794 21.0756 190.773 20.6802C190.762 20.5241 190.757 20.1858 190.757 19.6655H192.608C192.608 19.9569 192.619 20.1702 192.64 20.3055C192.65 20.4304 192.704 20.5189 192.799 20.5709C192.906 20.6125 193.065 20.6333 193.278 20.6333H195.097C195.704 20.6333 196.007 20.4564 196.007 20.1026C196.007 20.0297 195.985 19.9205 195.943 19.7748C195.751 19.2544 195.427 18.6196 194.97 17.8703C194.512 17.1107 194.017 16.3562 193.486 15.6069L195.129 14.2956C195.767 15.149 196.347 16.0284 196.868 16.9337C197.39 17.8287 197.73 18.604 197.89 19.2596C197.964 19.5406 198.001 19.8372 198.001 20.1494C198.001 20.9403 197.719 21.5908 197.156 22.1007C196.602 22.6002 195.826 22.85 194.826 22.85H193.629Z"
        fill="#1F2937"
      />
      <path
        d="M129.76 39.0277C129.03 39.0277 128.481 39.0214 128.113 39.0088C127.745 38.9899 127.479 38.9647 127.314 38.9332C127.149 38.9016 126.99 38.8512 126.838 38.7818C126.558 38.662 126.349 38.4918 126.209 38.2711C126.07 38.0504 126 37.7856 126 37.4767C126 37.2875 126.029 37.0858 126.086 36.8714L126.438 35.5473L126.98 35.7081L126.628 37.0511C126.584 37.2087 126.562 37.3569 126.562 37.4956C126.562 37.6721 126.603 37.8203 126.685 37.9401C126.768 38.0536 126.895 38.145 127.066 38.2144C127.18 38.2648 127.304 38.3026 127.437 38.3279C127.577 38.3531 127.828 38.3752 128.189 38.3941C128.551 38.4067 129.094 38.413 129.817 38.413H132.054C132.72 38.413 133.034 38.1419 132.996 37.5996V37.5807L132.892 36.4174L133.453 36.3701L133.558 37.5334V37.5523C133.59 37.8676 133.675 38.0914 133.815 38.2238C133.954 38.3499 134.18 38.413 134.491 38.413H134.595L134.633 38.7156L134.595 39.0277H134.491C133.945 39.0277 133.548 38.8575 133.301 38.517C133.168 38.681 132.996 38.8071 132.787 38.8953C132.577 38.9836 132.333 39.0277 132.054 39.0277H129.817H129.76ZM128.675 35.1028H129.436V35.8594H128.675V35.1028ZM130.264 35.1028H131.026V35.8594H130.264V35.1028Z"
        fill="#6B7280"
      />
      <path
        d="M134.484 38.413H135.864C136.2 38.413 136.445 38.3468 136.597 38.2144C136.756 38.0757 136.822 37.8739 136.797 37.6091V37.5807L136.692 36.4174L137.254 36.3701L137.359 37.5334C137.39 37.855 137.476 38.082 137.616 38.2144C137.755 38.3468 137.984 38.413 138.301 38.413L138.339 38.7156L138.301 39.0277C137.749 39.0277 137.349 38.8575 137.102 38.517C136.975 38.681 136.803 38.8071 136.588 38.8953C136.378 38.9836 136.137 39.0277 135.864 39.0277H134.484V38.413ZM134.132 40.0114H134.893V40.768H134.132V40.0114ZM135.731 40.0114H136.483V40.768H135.731V40.0114Z"
        fill="#6B7280"
      />
      <path
        d="M143.161 39.0277C142.647 39.0277 142.254 38.826 141.981 38.4224C141.734 38.826 141.324 39.0277 140.753 39.0277C140.252 39.0277 139.871 38.826 139.611 38.4224C139.351 38.826 138.945 39.0277 138.393 39.0277H138.202V38.413H138.393C139.021 38.413 139.335 38.1261 139.335 37.5523V36.389H139.868V37.5523C139.868 37.7163 139.922 37.9023 140.03 38.1103C140.144 38.3121 140.385 38.413 140.753 38.413C141.381 38.413 141.696 38.1261 141.696 37.5523V36.389H142.219V37.5523V37.5618C142.219 37.7194 142.279 37.8991 142.4 38.1009C142.521 38.3027 142.762 38.4035 143.123 38.4035C143.453 38.4035 143.698 38.3373 143.856 38.2049C144.015 38.0725 144.094 37.9054 144.094 37.7037C144.094 37.6343 144.085 37.5618 144.066 37.4861L143.752 36.3228L144.256 36.181L144.57 37.3443L144.58 37.3916C144.662 37.6879 144.821 37.9338 145.056 38.1293C145.297 38.3184 145.567 38.413 145.865 38.413L145.903 38.7156L145.865 39.0277C145.586 39.0277 145.325 38.9678 145.084 38.848C144.849 38.7219 144.646 38.5517 144.475 38.3373C144.367 38.5454 144.199 38.7125 143.971 38.8386C143.748 38.9647 143.479 39.0277 143.161 39.0277Z"
        fill="#6B7280"
      />
      <path
        d="M145.769 38.413H147.701C147.872 38.413 148.009 38.372 148.11 38.29C148.206 38.227 148.279 38.145 148.329 38.0441C148.386 37.9433 148.415 37.8361 148.415 37.7226C148.415 37.5776 148.377 37.4451 148.301 37.3253L146.178 34.3367L146.302 33.8733L149.748 32.6154L149.948 33.1923L146.883 34.3083L148.729 36.947C148.9 37.2056 148.986 37.483 148.986 37.7793C148.986 37.9559 148.951 38.123 148.881 38.2806C148.812 38.4382 148.713 38.5738 148.586 38.6873C148.371 38.9143 148.072 39.0277 147.692 39.0277H145.769V38.413Z"
        fill="#6B7280"
      />
      <path
        d="M152.865 39.0277C152.472 39.0277 152.164 38.9742 151.942 38.867C151.72 38.7535 151.558 38.5706 151.456 38.3184C151.355 38.0599 151.295 37.7005 151.276 37.2402L151.104 32.8897L151.666 32.8707L151.837 37.2402C151.85 37.5807 151.882 37.8298 151.932 37.9874C151.989 38.145 152.085 38.2554 152.218 38.3184C152.358 38.3815 152.573 38.413 152.865 38.413H152.96L152.998 38.7156L152.96 39.0277H152.865Z"
        fill="#6B7280"
      />
      <path
        d="M152.861 38.413H154.232C154.556 38.413 154.819 38.3468 155.022 38.2144C155.232 38.082 155.336 37.8613 155.336 37.5523C155.336 37.401 155.314 37.2465 155.27 37.0889L154.898 35.6797L155.441 35.5284L155.822 36.966C155.879 37.1929 155.907 37.3979 155.907 37.5807C155.907 38.0473 155.742 38.4067 155.412 38.6589C155.089 38.9048 154.695 39.0277 154.232 39.0277H152.861V38.413ZM153.29 39.9451V40.7396H152.49L152.481 39.9451H153.29ZM153.318 41.1274H154.07V41.8462H153.318V41.1274ZM154.08 39.9451H154.889L154.879 40.7396H154.08V39.9451Z"
        fill="#6B7280"
      />
      <path
        d="M158.933 40.8625L159.266 40.768C159.659 40.6671 159.967 40.5725 160.189 40.4842C160.411 40.396 160.595 40.2636 160.741 40.087C160.893 39.9168 160.97 39.6866 160.97 39.3966C160.97 39.3399 160.963 39.2547 160.951 39.1412L160.57 36.4174L161.131 36.3323L161.407 38.3562L161.436 38.5927C161.455 38.6936 161.477 38.8512 161.503 39.0656C161.522 39.198 161.531 39.3209 161.531 39.4344C161.531 39.819 161.439 40.1343 161.255 40.3802C161.071 40.6324 160.83 40.8279 160.532 40.9666C160.233 41.1116 159.859 41.244 159.408 41.3638L159.075 41.4584L158.933 40.8625ZM160.284 34.384H161.141V35.2352H160.284V34.384Z"
        fill="#6B7280"
      />
      <path
        d="M162.799 32.8897L163.361 32.8613L163.599 39.0183L163.037 39.0467L162.799 32.8897Z"
        fill="#6B7280"
      />
      <path
        d="M170.337 41.2598C170.064 41.3228 169.795 41.3544 169.528 41.3544C168.83 41.3544 168.281 41.1305 167.881 40.6829C167.488 40.2352 167.291 39.6299 167.291 38.867C167.291 38.2932 167.409 37.669 167.643 36.9943L168.186 37.2024C167.964 37.8203 167.853 38.3846 167.853 38.8953C167.853 39.4754 167.996 39.9262 168.281 40.2478C168.573 40.5757 168.986 40.7396 169.519 40.7396C169.734 40.7396 169.966 40.7112 170.213 40.6545C170.645 40.5473 170.969 40.459 171.184 40.3897C171.4 40.3203 171.578 40.2194 171.717 40.087C171.863 39.9546 171.936 39.7812 171.936 39.5668C171.936 39.466 171.93 39.3903 171.917 39.3399C171.886 39.1822 171.743 39.034 171.489 38.8953C171.235 38.7503 170.953 38.6242 170.642 38.517L170.252 38.3941L170.423 37.8077L170.842 37.9306C171.381 38.0946 171.825 38.2175 172.174 38.2995C172.53 38.3752 172.879 38.413 173.221 38.413L173.259 38.7156L173.221 39.0277C172.923 39.0277 172.615 39.0025 172.298 38.9521C172.393 39.0782 172.45 39.2011 172.469 39.3209C172.482 39.384 172.488 39.4786 172.488 39.6047C172.488 40.0649 172.301 40.4149 171.927 40.6545C171.559 40.8941 171.029 41.0958 170.337 41.2598Z"
        fill="#6B7280"
      />
      <path
        d="M173.126 38.413H173.345C173.713 38.413 173.964 38.3279 174.097 38.1576C174.23 37.9874 174.293 37.7888 174.287 37.5618L174.106 32.8897L174.658 32.8613L174.839 37.5334C174.852 37.773 174.811 38.0095 174.715 38.2428C174.62 38.4697 174.458 38.6589 174.23 38.8102C174.002 38.9552 173.707 39.0277 173.345 39.0277H173.126V38.413Z"
        fill="#6B7280"
      />
      <path
        d="M176.178 40.8531C176.945 40.6955 177.494 40.5662 177.824 40.4653C178.154 40.3707 178.402 40.2415 178.567 40.0776C178.732 39.9199 178.814 39.6898 178.814 39.3871C178.814 39.343 178.808 39.2579 178.795 39.1318L178.786 39.0277H177.463C176.999 39.0277 176.631 38.889 176.359 38.6116C176.086 38.3279 175.949 37.9433 175.949 37.4578C175.949 37.1551 176.013 36.8714 176.14 36.6066C176.273 36.3354 176.46 36.1179 176.701 35.954C176.942 35.7837 177.222 35.6986 177.539 35.6986C177.977 35.6986 178.345 35.8689 178.643 36.2093C178.941 36.5498 179.125 36.9975 179.195 37.5523L179.29 38.413H179.88L179.919 38.7156L179.88 39.0277H179.861H179.357V39.0561C179.37 39.2201 179.376 39.3367 179.376 39.4061C179.376 39.8411 179.268 40.1784 179.052 40.418C178.843 40.6639 178.532 40.8562 178.119 40.995C177.713 41.1337 177.104 41.2881 176.292 41.4584L176.178 40.8531ZM176.52 37.4578C176.52 38.0946 176.834 38.413 177.463 38.413H178.719L178.633 37.628C178.589 37.2119 178.468 36.8903 178.272 36.6633C178.075 36.43 177.828 36.3134 177.529 36.3134C177.212 36.3134 176.965 36.4269 176.787 36.6538C176.609 36.8808 176.52 37.1488 176.52 37.4578Z"
        fill="#6B7280"
      />
      <path
        d="M187.197 39.0277C186.79 39.0277 186.403 38.889 186.035 38.6116C185.883 38.744 185.712 38.848 185.521 38.9237C185.337 38.9931 185.144 39.0277 184.941 39.0277H182.047C181.844 39.0277 181.66 38.9836 181.495 38.8953C181.336 38.8008 181.209 38.6747 181.114 38.517C180.829 38.8449 180.4 39.0151 179.829 39.0277H179.782V38.413H179.829C180.502 38.4004 180.863 38.1072 180.914 37.5334L181.019 36.3701L181.571 36.4174L181.466 37.5807V37.5996C181.447 37.8644 181.489 38.0662 181.59 38.2049C181.692 38.3436 181.844 38.413 182.047 38.413H182.085L182.789 37.4388C183.088 37.0101 183.335 36.6822 183.532 36.4552C183.729 36.2282 183.941 36.0454 184.17 35.9067C184.398 35.768 184.655 35.6986 184.941 35.6986C185.245 35.6986 185.521 35.7743 185.769 35.9256C186.023 36.0769 186.223 36.285 186.368 36.5498C186.514 36.8146 186.587 37.1078 186.587 37.4294C186.587 37.6879 186.524 37.9275 186.397 38.1482C186.645 38.3247 186.911 38.413 187.197 38.413H187.33L187.368 38.7156L187.33 39.0277H187.197ZM184.941 38.413C185.131 38.413 185.309 38.3689 185.474 38.2806C185.645 38.186 185.778 38.0631 185.874 37.9117C185.975 37.7541 186.026 37.587 186.026 37.4105C186.026 37.1015 185.924 36.843 185.721 36.6349C185.518 36.4206 185.264 36.3134 184.96 36.3134C184.75 36.3134 184.56 36.3701 184.389 36.4836C184.217 36.5908 184.049 36.7421 183.884 36.9376C183.725 37.1267 183.516 37.4105 183.256 37.7888L182.799 38.413H184.941Z"
        fill="#6B7280"
      />
      <path
        d="M187.237 38.413H187.351C188.169 38.4004 189.013 38.268 189.883 38.0158L192.291 37.2875L190.206 36.1715C189.997 36.058 189.788 36.0013 189.578 36.0013C189.369 36.0013 189.169 36.058 188.979 36.1715C188.795 36.285 188.642 36.4489 188.522 36.6633L188.35 36.966L187.865 36.6728L188.046 36.3512C188.217 36.0422 188.436 35.8058 188.703 35.6419C188.969 35.4716 189.255 35.3865 189.559 35.3865C189.864 35.3865 190.168 35.4716 190.473 35.6419L192.929 37.0038L192.843 37.7131L192.348 37.8739C192.412 38.0315 192.516 38.1608 192.662 38.2617C192.808 38.3625 192.973 38.413 193.157 38.413H193.776L193.814 38.7156L193.776 39.0277H193.157C192.846 39.0277 192.567 38.9363 192.32 38.7535C192.072 38.5706 191.904 38.3342 191.815 38.0441L190.035 38.6116C189.147 38.889 188.249 39.0277 187.341 39.0277H187.237V38.413Z"
        fill="#6B7280"
      />
      <path
        d="M193.678 38.413H193.783C194.005 38.413 194.205 38.3594 194.383 38.2522C194.561 38.145 194.697 38 194.792 37.8172L195.497 36.4647C195.611 36.2503 195.769 36.0832 195.973 35.9634C196.182 35.8373 196.407 35.7743 196.648 35.7743C196.87 35.7743 197.074 35.8247 197.258 35.9256C197.505 36.0517 197.689 36.2314 197.81 36.4647C197.937 36.698 198 36.9628 198 37.2591C198 37.6564 197.898 38.0757 197.695 38.517C197.619 38.681 197.502 38.8165 197.343 38.9237C197.185 39.0246 197.013 39.075 196.829 39.075C196.677 39.075 196.534 39.0404 196.401 38.971L195.125 38.3184C194.967 38.5454 194.773 38.7219 194.545 38.848C194.316 38.9678 194.062 39.0277 193.783 39.0277H193.678V38.413ZM196.724 38.4414C196.801 38.4792 196.87 38.4981 196.934 38.4981C197.01 38.4981 197.077 38.4792 197.134 38.4414C197.191 38.3972 197.235 38.3436 197.267 38.2806L197.324 38.1387C197.445 37.8739 197.505 37.6091 197.505 37.3443C197.505 37.1362 197.464 36.9533 197.381 36.7957C197.299 36.6381 197.181 36.5183 197.029 36.4363C196.915 36.3796 196.794 36.3512 196.667 36.3512C196.515 36.3512 196.369 36.3922 196.23 36.4742C196.096 36.5561 195.992 36.6728 195.915 36.8241L195.42 37.7888L196.724 38.4414Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M236.928 30.5917V1.57143C232.589 1.57143 229.071 4.77694 229.071 8.73113V14.8668L223.291 11.5143C222.719 11.1827 221.981 11 221.214 11C220.447 11 219.71 11.1827 219.138 11.5143L209.148 17.3078C207.964 17.9945 207.071 19.5477 207.071 20.9209V32.5077C207.071 33.881 207.964 35.4341 209.148 36.1207L219.138 41.9143C219.71 42.2459 220.447 42.4286 221.214 42.4286C221.981 42.4286 222.719 42.2459 223.291 41.9143L223.294 41.9124L233.28 36.1207C233.371 36.0679 233.461 36.01 233.548 35.9474C233.978 35.6874 234.37 35.4422 234.684 35.2323C236.367 34.1064 236.928 32.5718 236.928 30.5917ZM216.437 30.5651C216.623 30.677 216.811 30.7889 217.002 30.9012L220.292 32.8408C220.51 32.9699 220.855 33.0469 221.214 33.0469C221.55 33.0469 221.872 32.9788 222.091 32.8645C222.275 32.7584 222.459 32.6506 222.644 32.5414L225.949 30.5934C226.4 30.3273 226.842 29.5602 226.869 29.0134C226.87 28.7963 226.871 28.5772 226.871 28.3564V24.4669C226.871 23.9346 226.441 23.16 225.991 22.8631C225.81 22.7545 225.627 22.6457 225.443 22.5369L222.137 20.5878C221.918 20.4587 221.573 20.3817 221.214 20.3817C220.879 20.3817 220.558 20.4494 220.338 20.5635C220.141 20.6776 219.942 20.794 219.742 20.912L216.479 22.8352C216.029 23.101 215.587 23.8665 215.56 24.4134C215.558 24.6545 215.557 24.8981 215.557 25.1438V28.9617C215.557 29.4938 215.986 30.268 216.437 30.5651Z"
        fill="url(#paint0_linear_1212_1954)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1212_1954"
          x1="236.928"
          y1="-5.89286"
          x2="199.514"
          y2="43.8114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A5B4FC" />
          <stop offset="1" stop-color="#4F46E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
