import { useEffect, useState } from "react";
import { PaymentAccount } from "../../../api/payment-account";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import toast from "../../../helpers/toast";
import { AccountType, Integration as IntegrationType } from "../../../types";
import { classNames } from "../../../utils/classNames";
import { AccountsModal } from "./AccountsModal";
import { paymentAccounts } from "../../../config/paymentAccounts";
import { ChevronLeftIcon, PencilAltIcon } from "@heroicons/react/outline";
import { Integration } from "../../../api/integration";
import { integratedAccounts } from "../../../config/integratedAccounts";
import { IntegratedAccountsModal } from "./IntegratedAccountsModal";

export const Accounts = () => {
  const [accounts, setAccounts] = useState<AccountType[]>();
  const [integrations, setIntegrations] = useState<IntegrationType[]>();
  const [selectedIntegratedAccount, setSelectedIntegratedAccount] =
    useState<IntegrationType>();
  const [selectedAccount, setSelectedAccount] = useState<AccountType>();

  const futureAccountTypes: AccountType[] | undefined =
    process.env.REACT_APP_FUTURE_PAYMENT_ACCOUNT_TYPES?.split(",")?.map(
      (paymentAccountName) => ({
        created: false,
        type: paymentAccountName?.toLowerCase(),
        disabled: true,
      })
    );

  const getAccounts = async () => {
    const { data } = await PaymentAccount.getAll();
    if (!data) {
      return;
    }
    setAccounts(
      [...data, ...(futureAccountTypes || [])].sort((a, b) =>
        Boolean(a.created) === Boolean(b.created)
          ? 0
          : Boolean(a.created)
          ? -1
          : 1
      )
    );
  };

  const getIntegrations = async () => {
    const { data } = await Integration.getAll();

    if (!data) {
      return;
    }

    setIntegrations(data);
  };

  useEffect(() => {
    (async () => {
      try {
        getAccounts();
        getIntegrations();
      } catch (e) {
        toast.error("خطا در دریافت اکانت ها");
      }
    })();
  }, []);

  return (
    <Card title="سرویس های متصل">
      {accounts && accounts?.length === 0 && (
        <div className="bg-indigo-100 rounded-xl px-5 py-4 flex flex-col items-center">
          <p className="text-sm text-center text-gray-500 mb-2.5 leading-6">
            هیچ اکانتی به دونو شما لینک نشده، با لینک کردن سرویس های مختلف را به
            دونو متصل کنید.
          </p>
          {/*
            <div className="bg-gradient-to-r to-gray-400 from-[#cbaeff] p-px rounded-lg">
            <Button type="card">اتصال اکانت</Button>
          </div>
          */}
          <Button type="outline">اتصال اکانت</Button>
        </div>
      )}

      {accounts && (
        <div className="flex flex-col gap-y-4">
          {accounts?.map((account) => {
            const accountLogoExists = Object.keys(paymentAccounts).includes(
              account?.type
            );
            const AccountLogo = paymentAccounts[account?.type]?.logo;
            return (
              <div
                className={classNames(
                  "border border-gray-100 rounded-xl px-5 py-4 flex gap-x-3.5 items-center",
                  account?.disabled
                    ? "cursor-not-allowed opacity-80"
                    : "cursor-pointer",
                  account?.disabled || !account?.created ? "" : "bg-gray-50"
                )}
                onClick={() =>
                  !account?.disabled && setSelectedAccount(account)
                }
              >
                <div
                  className={classNames(
                    account?.created
                      ? "bg-indigo-100"
                      : "border border-gray-200/80",
                    "w-10 h-10 flex items-center justify-center rounded-xl"
                  )}
                >
                  {paymentAccounts && accountLogoExists ? (
                    <>
                      {AccountLogo && (
                        <AccountLogo
                          className={classNames(
                            account?.created
                              ? "fill-indigo-500"
                              : "fill-gray-500",
                            "w-6 h-6"
                          )}
                        />
                      )}
                    </>
                  ) : (
                    <span className="text-[15px] text-black font-medium">
                      {account?.type?.toUpperCase()}
                    </span>
                  )}
                </div>
                <div className="grow">
                  <h3 className="text-[15px] text-black font-medium">
                    {(paymentAccounts &&
                      accountLogoExists &&
                      paymentAccounts[account?.type]?.name) ||
                      account?.type}
                  </h3>
                  <span className="text-gray-500 text-xs relative -top-0.5">
                    {account?.created
                      ? "فعال"
                      : account?.disabled
                      ? "به زودی"
                      : "برای شروع کلیک کنید"}
                  </span>
                </div>
                <div>
                  {!account?.disabled && (
                    <>
                      {!account?.created ? (
                        <ChevronLeftIcon className="w-5 h-5 text-gray-500" />
                      ) : (
                        <PencilAltIcon className="w-5 h-5 text-gray-500" />
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {integrations?.map((integration) => (
            <div
              className={classNames(
                "border border-gray-100 rounded-xl px-5 py-4 flex gap-x-3.5 items-center cursor-pointer",
                integration?.created ? " bg-gray-50" : ""
              )}
              onClick={() => setSelectedIntegratedAccount(integration)}
            >
              <div
                className={classNames(
                  integration?.created
                    ? "bg-indigo-100 text-indigo-500"
                    : "border border-gray-200/80 text-zinc-600",
                  "w-10 h-10 flex items-center justify-center rounded-xl text-sm font-black "
                )}
              >
                SE
              </div>
              <div className="grow">
                <h3 className="text-[15px] text-black font-medium">
                  {integratedAccounts[integration?.type]?.name}
                </h3>
                <span className="text-gray-500 text-xs relative -top-0.5">
                  {integration.created ? "فعال" : "برای شروع کلیک کنید"}
                </span>
              </div>
              <div>
                {!integration?.disabled && (
                  <>
                    {!integration?.created ? (
                      <ChevronLeftIcon className="w-5 h-5 text-gray-500" />
                    ) : (
                      <PencilAltIcon className="w-5 h-5 text-gray-500" />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedAccount && (
        <AccountsModal
          account={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          getAccounts={getAccounts}
        />
      )}
      {selectedIntegratedAccount && (
        <IntegratedAccountsModal
          account={selectedIntegratedAccount}
          setSelectedAccount={setSelectedIntegratedAccount}
          getAccounts={getIntegrations}
        />
      )}
    </Card>
  );
};
