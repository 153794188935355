import {
  CashIcon,
  CodeIcon,
  CogIcon,
  CollectionIcon,
  EmojiHappyIcon,
  FireIcon,
  FlagIcon,
  HomeIcon,
} from "@heroicons/react/outline";
import { ReactNode, SVGProps } from "react";
import { Dashboard } from "../pages/dashboard";
import { Donations } from "../pages/donations";
import { Login } from "../pages/login";
import { Overlays } from "../pages/overlays";
import { SetGateway } from "../pages/login/set-gateway";
import { DonationPage } from "../pages/donation-page";
import { ComingSoonPage } from "../pages/soon";
import { DonationGoal } from "../pages/donation-goal";

enum MenuGroup {
  Main,
  Settings,
}

export const routes: {
  title: string;
  id: string;
  path: string;
  Element: ReactNode;
  subPath?: boolean;
  group?: number;
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  menuType?: "dashboard";
  hidden?: boolean;
}[] = [
  {
    title: "ورود",
    id: "login",
    path: "/login",
    Element: <Login />,
    // hidden: true,
  },
  {
    title: "انتخاب نام درگاه",
    id: "set-gateway",
    path: "/login/set-gateway",
    Element: <SetGateway />,
  },
  {
    title: "داشبورد",
    id: "dashboard",
    path: "/dashboard",
    Element: <Dashboard />,
    Icon: HomeIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "پرداخت ها",
    id: "donations",
    path: "/donations",
    Element: <Donations />,
    Icon: CashIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "نمایه ها",
    id: "overlays",
    path: "/overlays/*",
    Element: <Overlays />,
    Icon: CollectionIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "هدف حمایت",
    id: "donation-goal",
    path: "/donation-goal",
    Element: <DonationGoal />,
    Icon: FlagIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "صفحهٔ حمایت",
    id: "donation-page",
    path: "/donation-page",
    Element: <DonationPage />,
    Icon: EmojiHappyIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "تنظیمات",
    id: "settings",
    path: "/settings",
    Element: <ComingSoonPage />,
    Icon: CogIcon,
    menuType: "dashboard",
    group: MenuGroup.Settings,
  },
  {
    title: "دریافت API",
    id: "api-settings",
    path: "/api-settings",
    Element: <ComingSoonPage />,
    Icon: CodeIcon,
    menuType: "dashboard",
    group: MenuGroup.Settings,
  },
];
