import { ReactNode } from "react";
import twitchIcon from "../images/twitch.svg";
import { classNames } from "../utils/classNames";
import { Spinner } from "./Spinner";

const buttonType = {
  primary: "bg-indigo-600 hover:opacity-80 text-white fill-white rounded-lg",
  secondary:
    "bg-white h-11 border border-[#E6E6E6] text-black transition-all transition text-white rounded-lg",
  white: `bg-white h-11 rounded-lg text-black`,
  outline: "border border-gray-300 rounded-lg h-10",
  "brands-discord": "border border-[#7289da] rounded-lg h-10 text-[#5e79df]",
  gray: "bg-[#F3F7FD] rounded-lg",
  card: "bg-[#F7F9FF] rounded-lg h-10",
};

export const Button = ({
  children,
  type,
  small,
  block,
  disabled,
  loading,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: ReactNode;
  type: keyof typeof buttonType;
  small?: boolean;
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <div
      {...props}
      className={classNames((loading || disabled) && "opacity-70")}
    >
      <button
        className={classNames(
          buttonType[type] || buttonType.primary,
          `inline-flex font-medium gap-x-4 transition-all text-sm items-center px-5 py-3 cursor-pointer button-${type}`,
          small && "px-3.5 py-2 text-sm",
          block && "w-full text-center justify-center"
        )}
        style={{
          boxShadow:
            type === "secondary"
              ? "0px 4px 2px rgba(113, 114, 125, 0.03)"
              : undefined,
        }}
      >
        {loading && <Spinner />}
        {children}
      </button>
    </div>
  );
};
