export const MiniFooter = () => {
  return (
    <div>
      <ul className="flex gap-x-3 items-center text-[#76738B] text-[15px] [&>li]:after:content-[''] [&>li]:after:w-1.5 [&>li]:after:h-1.5 [&>li]:after:bg-[#76738B] [&>li]:after:inline-flex [&>li:last-child]:after:hidden [&>li]:after:rounded-full [&>li]:after:mr-3">
        <li>گزارش مشکل</li>
        <li>حریم خصوصی</li>
      </ul>
    </div>
  );
};
