import { ReactNode } from "react";

export const Card = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="border border-gray-100 rounded-[20px] px-6 py-4 overflow-hidden">
      <h2 className="font-semibold mb-4">{title}</h2>
      {children}
    </div>
  );
};
