import React, { useEffect } from "react";
import { useModal } from "../../../hooks/useModal";
import { Integration as IntegrationType } from "../../../types";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { Button } from "../../../components/Button";
import { PaymentAccount } from "../../../api/payment-account";
import toast from "../../../helpers/toast";
import { integratedAccounts } from "../../../config/integratedAccounts";
import { Integration } from "../../../api/integration";

export const IntegratedAccountsModal = (props: {
  account: IntegrationType;
  setSelectedAccount: (account: IntegrationType | undefined) => void;
  getAccounts: () => void;
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;

  const { Modal, setIsOpen } = useModal({
    title: integratedAccounts[props?.account?.type]?.name,
    description: "اطلاعات اکانت اتصالی خود را با دقت وارد کنید.",
    onClose: () => {
      props.setSelectedAccount(undefined);
    },
  });

  useEffect(() => {
    if (!props?.account) {
      return;
    }
    setIsOpen(true);

    (async () => {
      if (!props?.account?.id) {
        return;
      }
      const { data } = await Integration.getById(props?.account?.id);
      if (!data) {
        return;
      }
      reset(data);
    })();
  }, [props?.account]);

  const onSubmit = async (payload: any) => {
    try {
      const existingAccount =
        props?.account?.created || props?.account?.metadata?.jwt;
      if (existingAccount) {
        const { data } = await Integration.update(props?.account?.type, {
          ...payload,
          type: props?.account?.type,
        });
        if (!data) {
          return;
        }
        toast.success("اکانت با موفقیت ویرایش شد.");
        props.getAccounts();
        return;
      }

      const newPayload = {
        ...payload,
        type: props?.account?.type,
      };
      const { data } = await Integration.create(newPayload);
      if (!data) {
        return;
      }
      toast.success("اتصال اکانت با موفقیت ایجاد شد.");

      props.getAccounts();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <Input
            type="text"
            id="metadata.jwt"
            placeholder="کد پذیرنده"
            validation={{ required: "لطفا کد پذیرنده را وارد کنید" }}
          />
          <Button type="primary" block disabled={!watch("metadata.jwt")}>
            ذخیره
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};
