import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { routes } from "./config/routes";
import { Toaster } from "react-hot-toast";

export const App = () => {
  return (
    <>
      <Toaster />

      <BrowserRouter>
        <Routes>
          {routes.map(({ id, path, Element, subPath }) => {
            if (!path) {
              return;
            }

            return (
              <Route
                key={id}
                path={`${path}${subPath ? "/*" : ""}`}
                element={Element}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};
