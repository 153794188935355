import { get, post } from "../config/user-api";

export const Auth = {
  loginEmail: ({ email }: { email: string }) => post(`auth/email`, { email }),
  verifyEmail: ({ id, code }: { id: string; code: string }) =>
    post(`auth/email/verify`, { id, code }),
  loginTwitch: (redirectUrl?: string) =>
    get(`auth/twitch${redirectUrl ? `?redirectUrl=${redirectUrl}` : ``}`),
  verifyTwitch: ({
    code,
    state,
    redirectUrl,
  }: {
    code: string;
    state: string;
    redirectUrl: string;
  }) => post(`auth/twitch/verify`, { code, state, redirectUrl }),
};
