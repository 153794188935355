import { Button } from "../../../components/Button";
import { Logo } from "../../../components/Logo";
import { NavbarTab } from "../../../components/NavbarTab";
import { routes } from "../../../config/routes";
import spaceship from "../../../images/spaceship.png";

export const Sidebar = () => {
  const dashboardTabs = routes?.filter(
    (route) => route?.menuType === "dashboard"
  );
  const ref: { [key: string | number]: any[] } = {};
  const groupTabs = dashboardTabs?.reduce((r, c) => {
    if (typeof c.group === "undefined") {
      return;
    }

    const groups: any = r || {};

    groups[c.group] =
      typeof groups[c.group] !== "undefined" ? [...groups[c.group], c] : [c];
    return groups;
  }, ref);

  return (
    <div className="bg-[#F8F9FF] h-screen w-[250px] fixed right-0 px-7 py-7 top-0">
      <div className="flex flex-col justify-between h-full gap-20">
        <div>
          <Logo />

          <ol className="navbar w-full list-none flex flex-col mt-10 relative divide-y divide-[#D4D7E5]">
            {Object.keys(groupTabs)?.map((groupTab) => {
              return (
                <ul className="flex flex-col gap-y-1 py-4">
                  {groupTabs[groupTab]!.map((props) => {
                    if (props?.hidden) {
                      return;
                    }

                    return <NavbarTab key={props?.id} {...props} />;
                  })}
                </ul>
              );
            })}
          </ol>
        </div>
        <div className="rounded-2xl bg-[#EAECFA] flex flex-col items-center w-full px-4 pb-4 pt-12 relative">
          <img
            src={spaceship}
            alt="spaceship"
            className="absolute -top-[25px]"
          />
          <h2 className="font-semibold mb-1">جایی گیر کردید؟</h2>
          <p className="text-sm opacity-70 mb-3 text-center">
            برای اطلاعات بیشتر به پایگاه دانش وارد شوید
          </p>
          <div className="w-full">
            <Button type="white" block>
              بیشتر بدانید
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
