import { useFormContext } from "react-hook-form";
import { classNames } from "../utils/classNames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { ErrorMessage } from "@hookform/error-message";

type IInputProps = {
  label?: string;
  placeholder?: string;
  helperText?: string;
  id?: string;
  type?: string;
  readOnly?: boolean;
  validation?: any;
  manual?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  className?: any;
  ref?: any;
  style?: any;
};

export default function Input({
  label,
  placeholder = "",
  helperText = "",
  id,
  type = "text",
  readOnly = false,
  validation,
  manual,
  style,
  ...rest
}: IInputProps) {
  const {
    register,
    getFieldState,
    formState: { errors },
  } = useFormContext() || (manual && { formState: { errors: {} } });

  const error = id && !manual && getFieldState(id)?.error;

  return (
    <div className="w-full relative">
      <div className="relative mt-1">
        <input
          {...(!manual &&
            id &&
            register(id, {
              valueAsNumber: type === "number",
              ...validation,
              setValueAs: (value) => value || undefined,
            }))}
          {...rest}
          type={type}
          name={id}
          id={id}
          readOnly={readOnly}
          className={classNames(
            "py-2 px-3 border border-gray-300 placeholder-neutral-800 h-[50px] text-sm",
            readOnly
              ? "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300"
              : !manual && error
              ? "focus:ring-red-500 border-red-500 border focus:border-red-500"
              : "focus:ring-indigo-500 border-gray-300 focus:border-indigo-500",
            "block w-full rounded-md"
          )}
          placeholder={label}
          aria-describedby={id}
          style={style}
        />
      </div>
      <div className="mt-1">
        {helperText !== "" && (
          <p className="text-xs text-gray-500">{helperText}</p>
        )}
        {id && (
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <>
                <div className="text-xs text-red-500 font-semibold flex items-center gap-x-1 pt-1">
                  <ExclamationCircleIcon className="text-xl text-red-500 w-4 h-4 absolute top-5 left-3" />

                  <span>{message}</span>
                </div>
              </>
            )}
          />
        )}
      </div>
    </div>
  );
}
