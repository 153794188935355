import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useProvideAuth";
import { LoginLayout } from "../components/LoginLayout";
import defaultAvatarImage from "../../../images/coyotes/1.png";
import Input from "../../../components/Input";
import { Button } from "../../../components/Button";
import { Gateway } from "../../../api/gateway";
import { type } from "os";

export const SetGateway = () => {
  const [slug, setSlug] = useState("");
  const { user, gateway } = useAuth();

  useEffect(() => {
    if (typeof user === "undefined") return;
    if (!user) {
      console.log(user);
      // window.location.href = "/login";
    }

    if (gateway) {
      window.location.href = "/dashboard";
    }
  }, [user, gateway]);

  const handleRegisterGateway = async () => {
    try {
      const { data } = await Gateway.create({
        slug,
      });

      if (!data) {
        return;
      }

      window.location.href = "/dashboard";
    } catch (e: any) {
      console.error(e);
    }
  };
  return (
    <LoginLayout>
      <div className="max-w-[380px]">
        <h1 className="text-3xl font-black mb-4">تکمیل ثبت نام 🎉</h1>
        <p className="text-black/80 leading-8 mb-8">
          برای تکمیل ثبت نام یک نام برای درگاه حمایت خود انتخاب کنید. این نام
          می‌تواند یوزرنیم توییچ شما یا نام دل‌خواه مورد نظر شما باشد.{" "}
        </p>
        <div className="min-height-[80px] p-4 rounded-xl bg-gray-100/80 mb-6">
          <div className="flex items-center gap-4">
            <img
              src={defaultAvatarImage}
              alt="avatar"
              className="w-12 h-12 rounded-xl object-center"
            />
            <div className="flex flex-col gap-1">
              <span className="text-black/80 text-[15px] font-bold">
                وارد شده با:
              </span>
              <span className="text-black/60 text-[15px]">{user?.email}</span>
            </div>
          </div>
        </div>

        <div
          className="rounded-[10px] overflow-hidden border border-gray-300 mb-6"
          style={{
            direction: "ltr",
          }}
        >
          <div className="flex -my-1">
            <div className="bg-gray-100 flex items-center my-1 px-3 text-gray-600">
              https://dono.gg/
            </div>
            <Input
              id="gatewayName"
              placeholder="نام درگاه"
              manual
              style={{
                borderRadius: 0,
                border: 0,
              }}
              onChange={(e: any) => setSlug(e.target.value)}
            />
          </div>
        </div>

        <Button
          type="primary"
          block
          onClick={handleRegisterGateway}
          disabled={!slug}
        >
          ثبت درگاه دونو
        </Button>
      </div>
    </LoginLayout>
  );
};
