import { get, post } from "../config/user-api";

export const Alert = {
  getAll: ({ page, type }: { page: number | string; type?: string }) =>
    get(`alert?page=${page}&limit=10${type ? `&type=${type}` : ""}`),
  Donation: {
    getAll: ({ page, type }: { page: number | string; type?: string }) =>
      get(`alert/donation?page=${page}&limit=10${type ? `&type=${type}` : ""}`),
  },
};
