export const ZarinpalIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.45648 3H9.21084C9.61316 3 9.81819 3.51274 9.53579 3.81388L4.79304 8.85585C4.50677 9.16106 4.01547 8.94945 4.01547 8.52216L4 3.48019C4 3.21568 4.20116 3 4.45648 3ZM20.5508 21H15.7895C15.3869 21 15.1817 20.4873 15.4643 20.1861L20.2062 15.1441C20.4927 14.8389 20.9843 15.0506 20.9843 15.4778L20.9998 20.5198C21.0075 20.7843 20.8024 21 20.5508 21ZM4.70747 16.638L16.8834 3.74894C17.8267 2.75035 19.3534 2.75035 20.2925 3.74894C21.2358 4.74747 21.2358 6.36349 20.2925 7.35769L8.11657 20.2511C7.17742 21.2496 5.64663 21.2496 4.70747 20.2511C3.76418 19.2525 3.76418 17.6365 4.70747 16.638Z"
      />
    </svg>
  );
};
