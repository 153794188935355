export const integratedAccounts: {
  [key: string]: {
    name: string;
    logo?: (props: any) => JSX.Element;
  };
} = {
  stream_elements: {
    name: "استریم المنتس",
  },
};
