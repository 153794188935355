import jupiter from "../../../images/overlays/jupiter.png";
import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";

export const NewOverlayCard = () => {
  return (
    <div className="border border-dashed border-[#D0D0D0] rounded-2xl overflow-hidden">
      <div className="h-[120px] w-full flex items-center justify-center">
        <img src={jupiter} className="-mb-4" />
      </div>
      <div className="p-4">
        <div className="mb-4">
          <h2 className="font-semibold mb-[2px]">نمایه جدید</h2>
          <span className="text-[13px] opacity-60 font-medium">
            یک نمایه جدید بسازید!
          </span>
        </div>
        <Link to="/overlays/new">
          <Button type="primary" block className="mt-4">
            ساختن نمایه
          </Button>
        </Link>
      </div>
    </div>
  );
};
