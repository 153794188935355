import { get, patch, post } from "../config/user-api";

export const Goal = {
  create: (props: {
    progressAmount: string;
    title?: string;
    maxAmount?: string;
  }) => post(`donation-goal`, props),
  getSelf: () =>
    get(`donation-goal/self`, {
      headers: {
        "Ignore-Interceptors": "true",
      },
    }),
  update: (props: any) => patch(`donation-goal/self`, props),
};
