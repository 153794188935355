import { Container } from "../../components/Container";
import { Logo } from "../../components/Logo";
import { LoginForm } from "./components/LoginForm";
import { useEffect, useState } from "react";
import { useLoginCallback } from "./useLoginCallback";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useProvideAuth";
import { VerifyLogin } from "./components/VerifyLogin";
import { LoginHero } from "./components/LoginHero";
import { MiniFooter } from "../../components/MiniFooter";
import { LoginLayout } from "./components/LoginLayout";

export const Login = () => {
  const [cookies] = useCookies();
  const [codeSent, setCodeSent] = useState("");
  const navigate = useNavigate();
  useLoginCallback(); // cookie is set here

  const { user, gateway } = useAuth();

  useEffect(() => {
    if (!cookies?.access_token) {
      return;
    }

    if (!user) {
      return;
    }

    if (gateway) {
      navigate("/dashboard");
    }
  }, [cookies, navigate, user, gateway]);

  return (
    <LoginLayout>
      {codeSent ? (
        <VerifyLogin {...{ codeSent, setCodeSent }} />
      ) : (
        <LoginForm {...{ setCodeSent }} />
      )}
    </LoginLayout>
  );
};
